<template>
    <b-container>
        <h4
            class="mb-4"
        >
            <b-button
                variant="link"
                @click="hasHistory() ? $router.go(-1) : $router.push('/')"
                class="mr-4"
            >
                <b-icon-arrow-left-circle scale="1.5"></b-icon-arrow-left-circle>
            </b-button>
            Assign Relationship
        </h4>
        <SearchPeople
            :key="assigned.length"
            v-model="assigned"
            v-on="{ addUser, removeUser }"
        ></SearchPeople>
        <b-list-group class="mt-2">
            <b-list-group-item
                variant="secondary"
            >
                Already Assigned <em v-if="assigned.length == 15">&middot; Only top 15 assigned are shown by last name.</em>
            </b-list-group-item>
            <b-list-group-item
                v-if="loading"
                class="text-center"
            >
                <b-spinner variant="primary"></b-spinner>
            </b-list-group-item>
            <b-list-group-item
                v-for="(assignee, idx) in assigned"
                :key="idx"
            >
                {{ assignee.first_name }} {{ assignee.last_name }}
                <b-checkbox
                    v-model="assignee.selected"
                    @change="removeUser(assignee)"
                    class="float-right"
                ></b-checkbox>
            </b-list-group-item>
        </b-list-group>
    </b-container>
</template>
<script>
import SearchPeople from "../components/SearchPeople.vue"

export default {
    components: { SearchPeople },
    data() {
        return {
            assigned: [],
            loading : false
        };
    },
    mounted() {
        this.getAlreadyAssignedPeople();
    },
    methods: {
        hasHistory() {
            return window.history.length > 2;
        },
        getRelationshipInfo() {
            
        },
        getAlreadyAssignedPeople() {
            this.loading = true;
            this.$api.get(
                "/api/admin/list-relationships",
                { params: {
                    target_id    : this.$route.query.target_id,
                    relationship : this.$route.query.relationship
                } }
            ).then(({ data }) => {
                console.log(data);
                this.assigned = data.records;
                this.loading  = false;
            });
        },
        addUser(user) {
            this.$api.post(
                "/api/admin/add-relationship",
                {
                    target_id    : this.$route.query.target_id,
                    person_id    : user.id,
                    relationship : this.$route.query.relationship
                }
            ).then(({ data: { success, message } }) => {
                if (success) {
                    this.getAlreadyAssignedPeople();
                    this.$bvToast.toast(message, {
                        title           : "Success!",
                        variant         : "success",
                        autoHideDelay   : 5000,
                        appendToast     : true
                    });
                } else {
                    this.$bvToast.toast(message, {
                        title           : "Fail!",
                        variant         : "danger",
                        autoHideDelay   : 5000,
                        appendToast     : true
                    });
                }
            });
        },
        removeUser(user) {
            this.$api.post(
                "/api/admin/remove-relationship",
                {
                    target_id    : this.$route.query.target_id,
                    person_id    : user.id,
                    relationship : this.$route.query.relationship
                }
            ).then(({ data: { success, message } }) => {
                if (success) {
                    this.getAlreadyAssignedPeople();
                    this.$bvToast.toast(message, {
                        title           : "Success!",
                        variant         : "success",
                        autoHideDelay   : 5000,
                        appendToast     : true
                    });
                } else {
                    this.$bvToast.toast(message, {
                        title           : "Fail!",
                        variant         : "danger",
                        autoHideDelay   : 5000,
                        appendToast     : true
                    });
                }
            });
        }
    }
}
</script>